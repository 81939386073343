import React from "react";

import LoginForm from "./loginForm";

export const LoginComp = () => {
  return (
    <div className="login-form">
      <h1>eMath</h1>
      <LoginForm />
    </div>
  );
};
